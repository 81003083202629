import url from "url";
import { useRouter } from "next/router";
import getUAServerSideProps from "@backend/getUAServerSideProps";
import LoginComponent from "components/login";
import Layout from "@components/layout";
import useAuth from "@organisms/auth/useAuth";
import { Box, Heading } from "grommet";
import React, { useEffect } from "react";

export default function Login({ referer }) {
  const router = useRouter();

  const auth = useAuth();

  useEffect(() => {
    if (auth.user) router.push(referer === "/login" ? "/" : referer);
  }, [auth]);

  return (
    <Layout seo={{ title: "Login", description: "Login to Coinscope" }}>
      <Box
        pad="large"
        background="#26272C"
        justify="center"
        gap="medium"
        margin={{ horizontal: "small", vertical: "large" }}
        width={{ width: "100%", max: "500px" }}
        alignSelf="center"
      >
        <Heading level="2" margin="none" textAlign="center">
          Login
        </Heading>
        <LoginComponent />
      </Box>
    </Layout>
  );
}

export async function getServerSideProps(context) {
  const referer = url.parse(context.req.headers.referer || "").pathname || "/";
  return getUAServerSideProps(context, { referer });
}
