import { useMemo, useState } from "react";
import Link from "next/link";
import styled from "styled-components";
import { Box, CheckBox, Text } from "grommet";
import StyledFirebaseAuth from "react-firebaseui/StyledFirebaseAuth";
import useIsLarge from "@cybertools/ui/es/hooks/useIsLarge";
import firebase, { sendEmailVerification } from "@helpers/firebase";
import { useNotifications } from "@cybertools/ui/es/organisms/notifications";

const getUiConfig = (uiShown) => ({
  signInFlow: "popup",
  signInOptions: [
    firebase.auth.GoogleAuthProvider.PROVIDER_ID,
    firebase.auth.TwitterAuthProvider.PROVIDER_ID,
  ],
  callbacks: {
    uiShown,
    signInSuccessWithAuthResult: (user) => {
      if (
        user.additionalUserInfo.isNewUser &&
        user.additionalUserInfo.providerId === "password"
      ) {
        sendEmailVerification();
      }

      return false;
    },
  },
});

const StyledAuthContainer = styled(Box)`
  cursor: default;

  ${({ disabled }) =>
    disabled &&
    `
    & button {
      pointer-events: none;
      opacity: 0.6;
  }
  `}
`;

function TermAndConditions() {
  return (
    <Text size="xsmall" margin={{ left: "xsmall" }} alignSelf="center">
      By continuing, you accept our{" "}
      <Link href="/terms" passHref>
        Terms of Service
      </Link>{" "}
      and{" "}
      <Link href="/privacy" passHref>
        Privacy Policy
      </Link>
      .
    </Text>
  );
}

export default function Login() {
  const [checkBoxHidden, setCheckBoxHidden] = useState(true);
  const [checked, setChecked] = useState(false);
  const large = useIsLarge();
  const notifications = useNotifications();

  const uiConfig = useMemo(
    () => getUiConfig(() => setCheckBoxHidden(false)),
    []
  );

  return (
    <Box
      flex
      height={{ min: "130px" }}
      pad={{ horizontal: "large", bottom: "large" }}
      gap="medium"
    >
      <StyledAuthContainer
        disabled={!checked}
        onClick={() =>
          !checked && notifications.warning("Please accept the terms.")
        }
      >
        <StyledFirebaseAuth
          uiConfig={uiConfig}
          firebaseAuth={firebase.auth()}
          isLarge={large}
        />
      </StyledAuthContainer>
      {!checkBoxHidden && (
        <CheckBox
          label={<TermAndConditions />}
          checked={checked}
          onChange={(event) => setChecked(event.target.checked)}
        />
      )}
    </Box>
  );
}
